<template>
    <div class="content-center">
        <div class="container-sm">
            <h2 class="title-center mb40 uppercase">
                {{ $t('auth.resetPasswordTitle') }}
            </h2>
            <div v-on:keyup.enter="onEnterSubmit">
                <label class="pure-material-textfield-outlined">
                    <input
                        placeholder=" "
                        type="text"
                        v-model="formData.email"
                        @blur="$v.formData.email.$touch()"
                        required
                    />
                    <span>{{ $t('global.email') }}</span>
                </label>
            </div>

            <div>
                <md-button
                    class=" mt16"
                    :class="{
                        'btn-default-blue': !$v.$invalid,
                        'btn-default-action': $v.$invalid
                    }"
                    style="margin-left:0;"
                    @click="submit"
                    @keyup.enter.native="submitResetPassword"
                    >{{ $t('auth.resetYourPassword') }}</md-button
                >
                <div class="column mt40">
                    <md-button class="c191456" to="/login">{{
                        $t('auth.login')
                    }}</md-button>
                    <md-button
                        href="https://bus-do-polski.pl/account"
                        target="_blank"
                        class="c191456"
                        >{{ $t('auth.createAccount') }}</md-button
                    >
                </div>
            </div>

            <div class="loading-overlay" v-if="loading">
                <div class="wrapper">
                    <md-progress-spinner
                        md-mode="indeterminate"
                        :md-stroke="2"
                    />
                </div>
            </div>
        </div>
        <success-modal
            :show="showSuccessModal"
            :message="$t('auth.passwordResetMessage')"
            @close="
                () => {
                    showSuccessModal = false;
                }
            "
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import SuccessModal from '../../components/modals/successModal';

export default {
    validations: {
        formData: {
            email: { email, required }
        }
    },

    components: {
        SuccessModal
    },

    data() {
        return {
            loading: false,
            showSuccessModal: false,
            formData: { email: '' }
        };
    },

    methods: {
        ...mapActions({
            resetPassword: 'auth/resetPassword'
        }),

        onEnterSubmit() {
            if (!this.$v.$invalid) {
                this.submitResetPassword();
            } else {
                this.$toast.error(this.$t('global.incorrectEmail'));
            }
        },

        submit() {
            if (!this.$v.$invalid) {
                this.submitResetPassword();
            }
        },

        async submitResetPassword() {
            this.loading = true;

            try {
                const formData = new FormData();
                formData.append('email', this.formData.email);
                formData.append('type', 'app');

                await this.resetPassword(formData);

                this.showSuccessModal = true;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t('global.somethingWrong'));
            }
        }
    }
};
</script>

<template>
    <md-dialog :md-fullscreen="false" :md-active="show">
        <div style="position:relative;">
            <md-button @click="close" class="closeX">
                X
            </md-button>
        </div>

        <div class="text-center mt16 ml16 mr16 mb16">
            <img
                :src="require('@assets/images/icon_success_tick.svg')"
                alt="kredyt"
                title="kredyt"
            />
            <div class="text-green uppercase" style="padding-right:36px">
                {{ $t('modals.success') }}
            </div>
        </div>

        <div class="text-center">
            <p class="pl16 pr16">
                {{ message }}
            </p>
        </div>
        <!-- <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">
                {{ $t('modals.close') }}
            </md-button>
        </md-dialog-actions> -->
    </md-dialog>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        }
    },

    data() {
        return {};
    },

    methods: {
        close() {
            this.$emit('close');
        }
    }
};
</script>
